// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API: "https://eenrollmentapidev.kumon.com.br/api",
  username: "eEnrollment",
  password: "VtB+ZxDJTsUFa7j_e-EL5kv6#NXpYR",
  appSecretKey: "Mve|gsxKmuB7j4yTEa*SD-h0t:LkUd6rlQ.pCcGPJWq#8",

  APIconsent: 'https://econsentapidev.kumon.com.br/api',
  URLConsent: 'https://econsentdev.kumon.com.br',
  URLConsentLA: 'https://econsentdev.kumonla.com'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
