import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cep'
})
export class CepPipe implements PipeTransform {

  transform(value: string, country: string) {

    let arr = value.split('')

    let masked = arr.splice(5, 0, '-')
    
  }

}
