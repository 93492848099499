import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideEmail',
})
export class HideEmailPipe implements PipeTransform {

  transform(email: string) {

    let result;

    if (email) {
      const [name, domain] = email.split('@');
      const mask = Math.floor(name.length / 2);

      result = `${name.substr(0, mask)}${new Array(name.length - mask + 1)
        .join('*')}@${domain}`;

      return result;

    }

    return (result = 'Não conseguimos identificar o email');
  }
}
